<template>
  <ElModal :name="name">
    <h3 class="h3">Личный кабинет переехал</h3>

    <div class="mt-4">
      Теперь он находится по адресу
      <span class="font-weight-bold">lk.ibls.one</span>. Уже перенаправили вас.
    </div>

    <div class="mt-4">
      <ElButton class="btn-primary" label="Спасибо" @click="hide" />
    </div>
  </ElModal>
</template>

<script>
import ElButton from '@/ui/ElButton.vue'
import ElModal from '@/ui/ElModal.vue'
import { useVfm } from 'vue-final-modal'

export default {
  name: 'ModalDomenMoved',

  components: {
    ElModal,
    ElButton,
  },

  setup() {
    const vfm = useVfm()

    return {
      vfm,
    }
  },

  data() {
    return {
      name: 'ModalDomenMoved',
    }
  },

  methods: {
    /**
     * @public
     */
    show() {
      this.vfm.open(this.name)
    },

    hide() {
      this.vfm.close(this.name)
    },
  },
}
</script>
