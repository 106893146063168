import { i18n } from '@/i18n/setup'

export const useCalendarOption = () => {
  const yearBeginDate = '01-01'
  const yearEndDate = '12-31'

  const YEAR = {
    id: 'year',
    name: i18n.global.t('date_time.year'),
  }

  const MONTH = {
    id: 'month',
    name: i18n.global.t('date_time.month'),
  }

  const WEEK = {
    id: 'week',
    name: i18n.global.t('date_time.week'),
  }

  const DAY = {
    id: 'day',
    name: i18n.global.t('date_time.day'),
  }

  const dayRegExp = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/

  return {
    DAY,
    dayRegExp,
    MONTH,
    YEAR,
    yearBeginDate,
    yearEndDate,
    WEEK,
  }
}

/**
 * @type {{ONLINE_LESSON: number, GOAL: number, CTP_TEST: number}}
 */
export const types = {
  CTP_TEST: 1,
  ONLINE_LESSON: 2,
  GOAL: 3,
  CERTIFICATION: 4,
}

/**
 * Цвета типов событий
 * @returns {Object}
 */
export const createColorsForUser = () => ({
  [types.CTP_TEST]: '#e71b7d',
  [types.ONLINE_LESSON]: '#E6F1FB',
  [types.GOAL]: '#1f97a2',
  [types.CERTIFICATION]: '#c3c3c3',
})

/**
 * Цвета типов событий для календаря ЛК СО
 * @returns {Object}
 */
export const createColorsForStudentFe = () => ({
  [types.CTP_TEST]: '#F484B5',
  [types.ONLINE_LESSON]: '#E6F1FB',
  [types.GOAL]: '#89C3C8',
  [types.CERTIFICATION]: '#c3c3c3',
})

/**
 * Цвета типов событий
 * @returns {Object}
 */
export const createColorsForAdmin = () => ({
  [types.CTP_TEST]: '#e71b7d',
  [types.ONLINE_LESSON]: '#31b71b',
  [types.GOAL]: '#1f97a2',
})

/**
 * @returns {number[]}
 */
export const createYears = function () {
  const list = [
    new Date().getFullYear(),
  ]

  for (let i = 0; i < 2; i++) {
    list.push(list[i] - 1)
  }

  return list
}

/**
 * @param {CalendarItemI} item
 * @returns {CalendarItemI}
 */
export const formatItem = function (item) {
  let { name } = item

  if (!name) {
    name = `#${item.id}`
  }

  const extraData = item.extraData || null

  if (item.type.id === types.ONLINE_LESSON && extraData) {
    const { managers, subjects } = extraData

    const manager = managers?.[0]
    const subject = subjects?.[0]

    extraData.lessonName = name

    name = `${subject?.name} (${manager?.lastName} ${manager?.firstName} ${manager?.middleName})`
  }

  const { type } = item

  name = `${type.name}: ${name}`

  return {
    id: item.id,
    type,
    name,
    dateStart: item.dateStart,
    dateEnd: item.dateEnd,
    timeStart: item.timeStart,
    timeEnd: item.timeEnd,
    extraData,
  }
}
