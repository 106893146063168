import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTariffs(state, payload) {
    state.tariffs = payload
  },

  setPhone(state, payload) {
    state.phone = payload
  },

  setEducationTypeId(state, payload) {
    state.educationTypeId = payload
  },
}
